import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      generateFactoryIdList: [],
      disposalSiteOpts: [],
      originalDisposalSiteByCompanyIdOpts: [],
      originalDisposalSiteByLicenceIdOpts: [],
      originalDisposalSiteId: null,
    };
  },

  methods: {

    // 処分事業者IDで処分場選択肢取得API ENTRUST.DISPOSAL_SITE_BY_COMPANYID + 処分事業者ID
    getDisposalSiteByCompanyIdApi(disposalCompanyId) {
      axios
        .get(API_URL.ENTRUST.DISPOSAL_SITE_BY_COMPANYID + disposalCompanyId)
        .then((res) => {
          if (this.form.entrustWasteList[0].wasteTypeId !== this.wasteType.INDUSTRIAL_WASTE) {
            this.disposalSiteOpts = res.data.disposalSiteIdList;
          } else {
            if (this.form.entrustDisposalCompanyInfo.disposalLicenceInfo.length === 0) {
              this.disposalSiteOpts = res.data.disposalSiteIdList;
            }
          }
          this.originalDisposalSiteByCompanyIdOpts = res.data.disposalSiteIdList;
        })
        .catch((err) => {
          this.disposalSiteOpts = [];
          console.log(err);
        });
    },

    // 処分許可証選択モーダル内で設定押下
    setDisposalLicence(licences) {
      this.checkStartDateAble(); // 有効開始日の設定
      const currentList = this.form.entrustDisposalCompanyInfo.disposalLicenceInfo.map(l => l.disposalLicenceId);
      licences.forEach(l => {
        // 追加された場合
        if (!currentList.includes(l.id)) {
          this.form.entrustDisposalCompanyInfo.disposalLicenceInfo.push({
            entrustLicenceId: null,
            disposalLicenceId: l.id,
            licenceNo: l.licenceNo,
          });
        }
      });
      // 削除された場合
      const newLicenceIds = licences.map(l => l.id);
      this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = this.form.entrustDisposalCompanyInfo.disposalLicenceInfo.filter(l => {
        return newLicenceIds.includes(l.disposalLicenceId);
      });

      this.getDisposalSiteApi(licences.map(l => l.id));
      //this.form.entrustDisposalCompanyInfo.disposalSiteId = null; // 処分場のリセット
    },

    // 一般処分許可証設定モーダル内で設定押下
    setDisposalCommonLicence(licences) {
      const currentList = this.form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.map(l => l.disposalLicenceId);
      licences.forEach(l => {
        if (!currentList.includes(l.id)) {
          this.form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.push({
            entrustLicenceId: null,
            disposalLicenceId: l.id,
            licenceNo: l.licenceNo,
            cityName: l.cityName,
          });
        }
      });
      // 削除された場合
      const newLicenceIds = licences.map(l => l.id);
      this.form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo = this.form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.filter(l => {
        return newLicenceIds.includes(l.disposalLicenceId);
      });
    },

    // 許可証別処分場選択肢取得API ENTRUST.TRANSSHIPMENT_BY_LICENCE + ？collectLicenceId＝{運搬許可証ID}
    getDisposalSiteApi(licenceIdList) {
      // 許可書が未設定の場合、運搬先選択肢の表示は処分事業者に紐づく処分場情報で表示する
      if (licenceIdList.length === 0) {
        this.getDisposalSiteByCompanyIdApi(this.form.entrustDisposalCompanyInfo.disposalCompanyId);
        return;
      }
      axios
        .get(API_URL.ENTRUST.DISPOSAL_SITE_BY_LICENCE + `?licenceIdList=${licenceIdList.join(",")}`)
        .then((res) => {
          if (this.form.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE) {
            this.disposalSiteOpts = res.data.disposalSiteSelectList;
          }
          this.originalDisposalSiteByLicenceIdOpts = res.data.disposalSiteSelectList;
        })
        .catch((err) => {
          this.disposalSiteOpts = [];
          console.log(err);
        });
    },
  },
};
