export default {
  data() {
    return {
      tempVal: {
        // postしない
        generateCompany: null, // 排出事業者
        generateFactory: [], // 排出事業所
      },

      form: {
        activeStartDate: null,
        activeEndDate: null,
        isUpdate: null,
        generateStoreList: [
          {
            generateStoreId: 0, // 排出事業場ID
          },
        ],
        registMemberName: null,
        generateMemberName: null,
        entrustDeliveryConfirmation: {
          isDeliveryConfirmSendFlag: null,
          generateDeliveryConfirmMail: null,
          collectDeliveryConfirmMail: null,
          disposalDeliveryConfirmMail: null,
          anyMailList: [null], // POSTなし
          anyMail1: null,
          anyMail2: null,
          anyMail3: null,
          anyMail4: null,
          anyMail5: null,
          qrInfo1: null,
          qrInfo2: null,
          qrInfo3: null,
          qrInfo4: null,
          qrInfo5: null,
        },
        // 廃棄物情報
        entrustWasteList: [
          {
            wasteTypeId: null,
            manifestTypeId: null,
            jwnetItemTypeId: null,
            constitutionItemIdList: [
              {
                constitutionItemId: null,
              },
            ],
            cbaItemId: null,
            wasteNameId: null,
            plansQuantity: null,
            plansWasteUnitTypeId: null,
            plansWasteTermTypeId: null,
            limitQuantity: null,
            limitWasteUnitTypeId: null,
            limitWasteTermTypeId: null,
            shapeTypeIdList: [
              {
                shapeTypeId: null,
              },
            ],
            disposalWayType: [
              {
                disposalWayTypeId: null,
              },
            ],
            processInfo: "",
            wdsFileName: null,
            cbaItemOpts: [], // POSTしない。POST前に削除する
            wasteNameOpts: [], // POSTしない。POST前に削除する
            activeStartDate: null,
            activeEndDate: null,
          },
        ],
        entrustCollectCompanyInfo: [
          {
            sectionNo: 1,
            collectCompanyId: null,
            contractStartDate: "",
            contractEndDate: "",
            isAutoUpdate: true,
            remarks: "",
            entrustCollectContractFileList: [],
            collectFactoryId: null,
            destinationTypeId: 2,
            collectWayTypeId: null,
            transshipmentStorageId: null,
            collectLicenceInfo: [
              {
                collectLicenceId: null,
              },
            ],
            commonCollectLicenceInfo: [
              {
                collectLicenceId: null,
              },
            ],
            companyOpts: [], // POSTしない。POST前に削除する
            transShipmentOpts: [], // POSTしない。POST前に削除する
            entrustCollectUnitPriceList: [],
          },
        ],
        entrustDisposalCompanyInfo: {
          disposalCompanyId: null,
          contractStartDate: "",
          contractEndDate: "",
          isAutoUpdate: true,
          remarks: "",
          entrustDisposalContractFileList: [],
          disposalSiteId: 0,
          disposalLicenceInfo: [
            {
              entrustLicenceId: null,
              disposalLicenceId: null,
            },
          ],
          commonDisposalLicenceInfo: [
            {
              entrustLicenceId: null,
              disposalLicenceId: null,
            },
          ],
          finalDisposalSiteList: [
            {
              entrustFinalDisposalSiteId: null,
              finalDisposalSiteId: null,
            },
          ],
          entrustDisposalUnitPriceList : []
        },
        memberInfo: {
          registerMember:{
          name:"string"
          },
          reportMember:{
          name:"string"
          }
        },
      },
    };
  },
};
