// import axios from "axios";

export default {
  data() {
    return {
      generateFactoryIdList: [],
    };
  },

  computed: {
    getShipmentLabel() {
      return (index) => {
        let str = "";
        const targetArr = this.form.entrustCollectCompanyInfo[index - 1];
        const shipmentId = targetArr.transshipmentStorageId;
        const shipmentOpts = targetArr.transShipmentOpts;
        const shipObj = shipmentOpts.filter((opt) => opt.id === shipmentId)[0];
        if (shipObj) {
          str = `${shipObj.name} ${shipObj.jwnetId}`;
          return str;
        } else {
          return "";
        }
      };
    },
  },
};
