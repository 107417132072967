export default {
  methods: {
    // 排出事業者情報 フォーマット
    formatGenerateResponse(data) {
      // 排出事業者
      this.tempVal.generateCompany = data.generateCompanyInfo.id;
      this.form.generateCompanyName = data.generateCompanyInfo.name;
      // 関連プルダウンの初期化
      this.changeGenerateCompanyHandler();

      // 排出事業所
      this.tempVal.generateFactory = data.generateFactoryInfo;

      // 初期排出事業所のIDリスト
      this.initialgenerateCompanyIds = this.tempVal.generateFactory.map(i => i.id)

      // 事業場名
      this.form.generateStoreList = data.generateStoreInfo;

      // バリデーションも追加
      this.validate.generateStore = []
      this.form.generateStoreList.forEach(() => {
        const newValidate = {
          contractStartDate: { isValid: true, errMsg: ""},
          contractEndDate: { isValid: true, errMsg: ""},
        };
        this.validate.generateStore.push(newValidate);
      })
    },

    // 廃棄物情報 フォーマット
    formatWasteListResponse(data) {
      this.form.entrustWasteList = [];
      this.validate.wasteInfo = [];
      this.originalWasteTypeId = data.wasteList[0].wasteTypeInfo.id;
      data.wasteList.forEach((wl) => {
        let obj = {
          entrustWasteInfoId: wl.entrustWasteInfoId,
          wasteTypeId: wl.wasteTypeInfo.id,
          wasteTypeName: wl.wasteTypeInfo.name,
          manifestTypeId: wl.manifestTypeInfo.id,
          manifestTypeName: wl.manifestTypeInfo.name,
          jwnetItemTypeId: wl.jwnetItemTypeInfo.id,
          cbaItemId: wl.cbaItemInfo.id,
          wasteNameId: wl.wasteNameInfo.id,
          constitutionItemIdList: wl.constitutionItemList.map((c) => {
            return { constitutionItemId: c.id };
          }),
          plansQuantity: wl.plansQuantity,
          plansWasteUnitTypeId: wl.plansWasteUnitType
            ? wl.plansWasteUnitType.id
            : null,
          plansWasteTermTypeId: wl.plansWasteTermType
            ? wl.plansWasteTermType.id
            : null,
          limitQuantity: wl.limitQuantity,
          limitWasteUnitTypeId: wl.limitWasteUnitType
            ? wl.limitWasteUnitType.id
            : null,
          limitWasteTermTypeId: wl.limitWasteTermType
            ? wl.limitWasteTermType.id
            : null,
          shapeTypeIdList: wl.shapeTypeList.map((s) => {
            return { shapeTypeId: s.id };
          }),
          processInfo: wl.processInfo,
          wdsFileName: wl.wdsFileName,
          disposalWayType: wl.disposalWayTypeList.map((d) => {
            return { disposalWayTypeId: d.id };
          }),
          cbaItemOpts: [], // POSTしない。POST前に削除する
          wasteNameOpts: [], // POSTしない。POST前に削除する,
          activeStartDate: wl.activeStartDate,
          activeEndDate: wl.activeEndDate
        };
        // 関連プルダウンの初期化
        this.changeJwnetItemTypeHandler(obj);
        this.changeCbaItemTypeHandler(obj);
        this.form.entrustWasteList.push(obj);

        // バリデーションも追加
        const newValidate = {
          jwnetItemTypeId: { isValid: true, errMsg: "" },
          cbaItemId: { isValid: true, errMsg: "" },
          wasteNameId: { isValid: true, errMsg: "" },
          constitutionItemId: { isValid: true, errMsg: "" },
          shapeTypeId: { isValid: true, errMsg: "" },
          disposalWayTypeId: { isValid: true, errMsg: "" },
          activeStartDate: { isValid: true, errMsg: ""},
          activeEndDate: { isValid: true, errMsg: ""},
          plansQuantity: { isValid: true, errMsg: "" }
        };
        this.validate.wasteInfo.push(newValidate);
      });
    },

    // 運搬委託契約先情報 フォーマット
    formatCollectCompanyResponse(data) {
      this.form.entrustCollectCompanyInfo = [];
      this.validate.entrustCollectCompanyInfo = [];
      this.initialCollectCommonLicenseIds = [];
      this.initialCollectLicenseIds = [];
      const entrustWasteInfoIdList = data.wasteList.map(w => w.entrustWasteInfoId);
      data.entrustCollectCompanyInfo.forEach((cc, collectCompanyIndex) => {
        // collectLicenceInfo,
        let obj = {
          entrustCollectCompanyId: cc.entrustCollectCompanyId,
          sectionNo: cc.sectionNo,
          collectCompanyId: cc.collectCompanyInfo.id,
          collectCompanyName: cc.collectCompanyInfo.name,
          contractStartDate: cc.contractStartDate,
          contractEndDate: cc.contractEndDate,
          isAutoUpdate: cc.isAutoUpdate,
          remarks: cc.remarks,
          entrustCollectContractFileList: cc.entrustCollectContractFileList,
          collectFactoryId: cc.collectFactoryInfo.id,
          destinationTypeId: cc.destinationInfo
            ? cc.destinationInfo.destinationTypeInfo.id
            : null,
          collectWayTypeId: cc.collectWayTypeInfo
            ? cc.collectWayTypeInfo.id
            : null,
          transshipmentStorageId: cc.destinationInfo
            ? cc.destinationInfo.id
            : null,
          companyOpts: [],
          transShipmentOpts: [],
          collectUnitPriceFileName: null, // POSTしない
        };

        // 委託契約運搬単価
        obj.entrustCollectUnitPriceList = cc.collectUnitPriceList.map(
            c => {
            return {
              id: c.id,
              generateStoreName: c.generateStoreInfo.name,
              jwnetItemTypeName: c.jwnetItemTypeInfo.name,
              entrustWasteInfoIndex: entrustWasteInfoIdList.indexOf(c.entrustWasteInfoId) + 1,
              unitPrice: c.unitPrice,
              wasteUnitTypeName: c.priceWasteUnitTypeInfo ? c.priceWasteUnitTypeInfo.name : null
            };
          })

        // 運搬許可証
        obj.collectLicenceInfo = cc.collectLicenceInfo.map((licence) => {
          return {
            entrustLicenceId: licence.entrustLicenceId,
            collectLicenceId: licence.licenceId,
            licenceNo: licence.licenceNo,
          };
        });

        // 一般廃棄物運搬許可証
        obj.commonCollectLicenceInfo = cc.commonCollectLicenceInfo.map((licence) => {
          return {
            entrustLicenceId: licence.entrustCommonLicenceId,
            collectLicenceId: licence.licenceId,
            licenceNo: licence.licenceNo,
            cityName: licence.cityName
          };
        });

        // 契約書PDF
        if (cc.entrustCollectContractFileList.length) {
          obj.entrustCollectContractFileList =
            cc.entrustCollectContractFileList.map((file) => {
              return {
                entrustContractFileId: file.id,
                contractFileName: file.contractFileName,
                contractFileRemarks: file.contractFileRemarks,
              };
            });
        }

        this.form.entrustCollectCompanyInfo.push(obj);
        //初期の運搬許可証IDリスト
        cc.collectLicenceInfo.forEach(license => this.initialCollectLicenseIds.push(license.licenceId))

        // バリデーションも追加
        const newValidate = {
          collectCompanyId: { isValid: true, errMsg: "" },
          contractStartDate: { isValid: true, errMsg: "" },
          contractEndDate: { isValid: true, errMsg: "" },
          // 経路情報 ============================
          collectFactoryId: { isValid: true, errMsg: "" },
          collectWayTypeId: { isValid: true, errMsg: "" },
          transshipmentStorageId: { isValid: true, errMsg: "" },
        };
        this.validate.entrustCollectCompanyInfo.push(newValidate);

        // 関連プルダウンの初期化
        this.changeCollectCompanyIdHandler(obj);

        let licenceIdOfNextSection = [];
        if (collectCompanyIndex < data.entrustCollectCompanyInfo.length - 1) {
          licenceIdOfNextSection.push(data.entrustCollectCompanyInfo[collectCompanyIndex + 1].collectLicenceInfo.map(cl => cl.licenceId))
        }

        this.getTransShipmentApi(obj, licenceIdOfNextSection);
      });
    },

    // 処分委託契約先情報 フォーマット
    formatDisposalCompanyResponse(data) {
      const entrustWasteInfoIdList = data.wasteList.map(w => w.entrustWasteInfoId);
      this.initialDisposalLicenseIds = [];
      this.initialDisposalCommonLicenseIds = [];
      const dc = data.entrustDisposalCompanyInfo;

      this.form.entrustDisposalCompanyInfo = {
        entrustDisposalCompanyId: dc.entrustDisposalCompanyId,
        disposalCompanyId: dc.disposalCompanyInfo.id,
        disposalCompanyName: dc.disposalCompanyInfo.name,
        contractStartDate: dc.contractStartDate,
        contractEndDate: dc.contractEndDate,
        isAutoUpdate: dc.isAutoUpdate,
        remarks: dc.remarks,
        entrustDisposalContractFileList: dc.entrustDisposalContractFileList,
        disposalSiteId: dc.destinationInfo ? dc.destinationInfo.id : null,
        disposalUnitPriceFileName: null, // POSTしない
        entrustDisposalUnitPriceList: [],
      };

      // 委託契約処分単価
      this.form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = dc.disposalUnitPriceList.map(
          d => {
          return {
            id: d.id,
            generateStoreName: d.generateStoreInfo.name,
            jwnetItemTypeName: d.jwnetItemTypeInfo.name,
            entrustWasteInfoIndex: entrustWasteInfoIdList.indexOf(d.entrustWasteInfoId) + 1,
            unitPrice: d.unitPrice,
            wasteUnitTypeName: d.priceWasteUnitTypeInfo ? d.priceWasteUnitTypeInfo.name : null
          };
        })

      // 処分許可証
      this.form.entrustDisposalCompanyInfo.disposalLicenceInfo =
        dc.disposalLicenceInfo.map((licence) => {
          return {
            entrustLicenceId: licence.entrustLicenceId,
            disposalLicenceId: licence.licenceId,
            licenceNo: licence.licenceNo,
          };
        });

      // 一般廃棄物処分許可証
      this.form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo =
        dc.commonDisposalLicenceInfo.map((licence) => {
          return {
            entrustLicenceId: licence.entrustCommonLicenceId,
            disposalLicenceId: licence.licenceId,
            licenceNo: licence.licenceNo,
            cityName: licence.cityName,
          };
        });

      //初期の物処分許可証IDリスト
      dc.disposalLicenceInfo.map(license => this.initialDisposalLicenseIds.push(license.licenceId));

      // 契約書PDF
      if (dc.entrustDisposalContractFileList.length) {
        this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList =
          dc.entrustDisposalContractFileList.map((file) => {
            return {
              entrustContractFileId: file.id,
              contractFileName: file.contractFileName,
              contractFileRemarks: file.contractFileRemarks,
            };
          });
      }

      // 最終処分場情報
      if (dc.FinalDisposalSiteList && dc.FinalDisposalSiteList.length) {
        this.form.entrustDisposalCompanyInfo.finalDisposalSiteList = dc.FinalDisposalSiteList.map((site) => site); // 本番データ格納想定
      }else{
        /**
         * 委託契約処分事業者情報
         * ダミーデータを格納。正式データ格納後else文削除想定
         */
        this.form.entrustDisposalCompanyInfo.finalDisposalSiteList = this.dummyFinalDisposalSiteList;
      }

      this.getDisposalSiteByCompanyIdApi(this.form.entrustDisposalCompanyInfo.disposalCompanyId);

      // 関連プルダウンの初期化
      this.getDisposalSiteApi(dc.disposalLicenceInfo.map((l) => l.licenceId));


      this.originalDisposalSiteId = dc.destinationInfo ? dc.destinationInfo.id : null;
    },

    // 受渡確認票へ変換
    formatEntrustDeliveryConfirmationResponse(data) {
      let anyMailList = [];
      let anyMailValidateList = []

      anyMailList.push(data.entrustDeliveryConfirmation.anyMail1)
      anyMailValidateList.push({ isValid: true, errMsg: "" })

      anyMailList.push(data.entrustDeliveryConfirmation.anyMail2)
      anyMailValidateList.push({ isValid: true, errMsg: "" })

      anyMailList.push(data.entrustDeliveryConfirmation.anyMail3)
      anyMailValidateList.push({ isValid: true, errMsg: "" })

      anyMailList.push(data.entrustDeliveryConfirmation.anyMail4)
      anyMailValidateList.push({ isValid: true, errMsg: "" })

      anyMailList.push(data.entrustDeliveryConfirmation.anyMail5)
      anyMailValidateList.push({ isValid: true, errMsg: "" })

      this.form.entrustDeliveryConfirmation = {
        isDeliveryConfirmSendFlag: data.entrustDeliveryConfirmation.isDeliveryConfirmSendFlag,
        generateDeliveryConfirmMail: data.entrustDeliveryConfirmation.generateDeliveryConfirmMail,
        collectDeliveryConfirmMail: data.entrustDeliveryConfirmation.collectDeliveryConfirmMail,
        disposalDeliveryConfirmMail: data.entrustDeliveryConfirmation.disposalDeliveryConfirmMail,
        anyMailList: anyMailList,
        qrInfo1: data.entrustDeliveryConfirmation.qrInfo1,
        qrInfo2: data.entrustDeliveryConfirmation.qrInfo2,
        qrInfo3: data.entrustDeliveryConfirmation.qrInfo3,
        qrInfo4: data.entrustDeliveryConfirmation.qrInfo4,
        qrInfo5: data.entrustDeliveryConfirmation.qrInfo5,
      }

      // バリデーションチェックの初期化
      this.validate.anyMailList = anyMailValidateList;
    }
  },
};
