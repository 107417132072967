<template>
  <div v-if="isShow" class="c-modal lg">
    <div class="c-modal__overlay"></div>
    <div class="c-modal__content">
      <div class="c-modal__head">
        <h5 class="c-lead-s">運搬許可証番号</h5>
        <span class="c-modal__head__close" @click="closeModalHandler"></span>
      </div>
      <div class="c-modal__body">
        <table class="c-modal__table">
          <thead>
            <tr>
              <th></th>
              <th>許可証番号</th>
              <th>自治体名</th>
              <th>特別管理産業廃棄物</th>
            </tr>
          </thead>
          <tbody class="c-modal__body__licenceList" v-if="licenceList.length">
            <tr v-for="licence in licenceList" :key="licence.id">
              <td class="c-checkbox is-checkbox" :class="{ disabled: initialCollectLicenseIds.includes(licence.id) }">
                <input
                  :id="'licence-cb-' + licence.id"
                  type="checkbox"
                  :value="licence"
                  v-model="target.licences"
                />
                <label
                  class="c-checkbox__label"
                  :for="'licence-cb-' + licence.id"
                >
                  <span class="c-checkbox__box"></span>
                </label>
              </td>
              <td>{{ licence.licenceNo }}</td>
              <td>{{ dottedText(licence.municipalityName, 30) }}</td>
              <td>{{ licence.isSpecial ? '有' : 'なし' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="c-modal__foot">
        <div class="c-btn primary small" @click="clickSetHandler">設定</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import {API_URL} from "@/const";

export default {
  props: {
    isShow: {
      type: Boolean,
    },
    targetEntrustCollect: {
      type: Object,
    },
    default: {
      type: Array,
    },
    initialCollectLicenseIds: {
      type: Array,
    }
  },
  mixins: [common, validation],
  data() {
    return {
      licenceList: [],
      selectedLicences: [],
    };
  },

  methods: {
    closeModalHandler() {
      this.$emit("update:isShow", false);
      this.licenceList = [];
    },

    clickSetHandler() {
      this.$emit("setSelectedCollectLicence", this.target.licences);
      this.$emit("update:isShow", false);
    },

    // 運搬許可証選択肢取得API
    getCollectSiteSelectListApi() {
      axios
        .get(API_URL.ENTRUST.COLLECT_LICENCE + this.targetEntrustCollect.collectCompanyId)
        .then((res) => {
          this.licenceList = res.data.licenceList;
          this.target.licences = this.licenceList.filter(sl => {
            return this.target.licences.find(l => l.id === sl.id);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  watch: {
    isShow() {
      if (this.isShow) {
        this.getCollectSiteSelectListApi();
      }
    },
    default() {
      const tmp = [];
      this.default.forEach(l => {
        tmp.push({
          id : l.collectLicenceId,
          licenceNo: l.licenceNo,
        });
      });

      if (this.target) {
         this.target.licences = tmp;
      } else {
        this.selectedLicences.push({
          collectCompanyId: this.targetEntrustCollect.collectCompanyId,
          licences: tmp,
        });
      }
    }
  },

  computed: {
    target() {
      return this.selectedLicences.find(l => l.collectCompanyId === this.targetEntrustCollect.collectCompanyId);
    }
  }
};
</script>
