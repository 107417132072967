import axios from "axios";
import { API_URL, DOMAIN } from "@/const";

export default {
  data() {
    return {
      targetEntrustCollect: {
        collectCompanyId: 0,
        collectLicenceInfo: [],
      },
      targetEntrustCollectCommon: {
        collectCompanyId: 0,
        collectLicenceInfo: [],
      },
    };
  },

  methods: {
    // かたまりを追加
    addCollectInfoHandler() {
      // 区間 > 運搬先を積替保管に変更
      this.form.entrustCollectCompanyInfo.forEach((cc) => {
        cc.destinationTypeId = DOMAIN.DESTINATION_TYPE.TRANSSHIPMENT;
      });

      const newList = {
        entrustCollectCompanyId: null,
        sectionNo: null,
        collectCompanyId: null,
        contractStartDate: "",
        contractEndDate: "",
        isAutoUpdate: true,
        remarks: "",
        entrustCollectContractFileList: [],
        collectFactoryId: null,
        destinationTypeId: DOMAIN.DESTINATION_TYPE.DISPOSAL_SITE,
        collectWayTypeId: null,
        transshipmentStorageId: null,
        collectLicenceInfo: [],
        commonCollectLicenceInfo: [],
        companyOpts: [],
        transShipmentOpts: [],
      };

      const newValidate = {
        collectCompanyId: { isValid: true, errMsg: "" },
        contractStartDate: { isValid: true, errMsg: "" },
        contractEndDate: { isValid: true, errMsg: "" },
        collectFactoryId: { isValid: true, errMsg: "" },
        collectWayTypeId: { isValid: true, errMsg: "" },
        transshipmentStorageId: { isValid: true, errMsg: "" },
      };

      this.form.entrustCollectCompanyInfo = [
        ...this.form.entrustCollectCompanyInfo,
        newList,
      ];
      this.validate.entrustCollectCompanyInfo = [
        ...this.validate.entrustCollectCompanyInfo,
        newValidate,
      ];

      this.applySectionNo();
    },

    // かたまりを削除
    removeCollectInfoHandler(index) {
      this.errMsgs = [];
      // 前の区間を処分区間に変更してから、削除対象を削除する
      if (index > 0) {
        this.form.entrustCollectCompanyInfo[index - 1].destinationTypeId = this.destinationType.DISPOSAL_SITE;
      }

      this.form.entrustCollectCompanyInfo =
      this.form.entrustCollectCompanyInfo.filter((v, i) => i !== index);

      this.validate.entrustCollectCompanyInfo =
      this.validate.entrustCollectCompanyInfo.filter((v, i) => i !== index);

      // 現在の廃棄物区分は産業廃棄物以外場合、設定した許可証をリセット
      if (this.form.entrustWasteList[0].wasteTypeId !== this.wasteType.INDUSTRIAL_WASTE) {
        this.form.entrustCollectCompanyInfo.forEach(ecc => {
          ecc.collectLicenceInfo = [];
          ecc.transshipmentStorageId = null;
        });
        this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = [];
      }

      this.isRemoveCollectSection = true;
      this.applySectionNo();
    },

    // 運搬事業者変更時
    changeCollectCompanyIdHandler(entrustCollect) {
      if (this.resettable && !this.isRemoveCollectSection)
        entrustCollect.collectLicenceInfo = []; // 運搬許可証のリセット
      if (this.resettable && !this.isRemoveCollectSection)
        entrustCollect.collectFactoryId = null; // 運搬事業所のリセット
      if (this.resettable && !this.isRemoveCollectSection)
        entrustCollect.transshipmentStorageId = null; // 積替保管のリセット
      if (this.resettable && !this.isRemoveCollectSection)
        entrustCollect.transShipmentOpts = []; // 積替保管のプルダウンリセット
      this.isRemoveCollectSection = false;

      // 運搬事業者の取得
      return axios
        .get(
          API_URL.ENTRUST.COLLECT_SELECT_FACTORY +
            entrustCollect.collectCompanyId
        )
        .then((res) => {
          entrustCollect.companyOpts = res.data.generateFactoryIdList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 区間を連番にする
    applySectionNo() {
      this.form.entrustCollectCompanyInfo.map((collectCompany, index) => {
        collectCompany.sectionNo = index + 1;
      });
    },

    // 運搬許可証選択モーダル展開
    clickShowCollectLicenceModalHandler(entrustCollect) {
      this.targetEntrustCollect = entrustCollect;
      this.isCollectLicenceSelectModalShow = true;
    },

    // 一般廃棄物運搬許可証選択モーダル展開
    clickShowCollectCommonLicenceModalHandler(entrustCollect) {
      this.targetEntrustCollectCommon = entrustCollect;
      this.isCollectCommonLicenceSelectModalShow = true;
    },

    // 運搬許可証選択モーダル内で設定押下
    setCollectLicence(licences) {
      this.checkStartDateAble(); // 有効開始日の設定
      const currentList = this.targetEntrustCollect.collectLicenceInfo.map(
        (l) => l.collectLicenceId
      );
      if(licences.length === 0) {
        this.targetEntrustCollect.transshipmentStorageId = null; // 積替保管のリセット
      }
      licences.forEach((l) => {
        // 追加された場合
        if (!currentList.includes(l.id)) {
          this.targetEntrustCollect.collectLicenceInfo.push({
            entrustLicenceId: null,
            collectLicenceId: l.id,
            licenceNo: l.licenceNo,
          });
        }
      });
      // 削除された場合
      const newLicenceIds = licences.map((l) => l.id);
      this.targetEntrustCollect.collectLicenceInfo =
        this.targetEntrustCollect.collectLicenceInfo.filter((l) => {
          return newLicenceIds.includes(l.collectLicenceId);
        });

      this.getTransShipmentApi(this.targetEntrustCollect, []);
    },

    // 一般廃棄物運搬許可証選択モーダル内で設定押下
    setCollectCommonLicence(licences) {
      this.checkStartDateAble(); // 有効開始日の設定
      const currentList = this.targetEntrustCollectCommon.commonCollectLicenceInfo.map(
        (l) => l.collectLicenceId
      );
      licences.forEach((l) => {
        // 追加された場合
        if (!currentList.includes(l.id)) {
          this.targetEntrustCollectCommon.commonCollectLicenceInfo.push({
            entrustLicenceId: null,
            collectLicenceId: l.id,
            licenceNo: l.licenceNo,
          });
        }
      });
      // 削除された場合
      const newLicenceIds = licences.map((l) => l.id);
      this.targetEntrustCollectCommon.commonCollectLicenceInfo =
        this.targetEntrustCollectCommon.commonCollectLicenceInfo.filter((l) => {
          return newLicenceIds.includes(l.collectLicenceId);
        });
    },

    // 積替保管場所選択肢取得API ENTRUST.TRANSSHIPMENT_BY_LICENCE + ？collectLicenceId＝{運搬許可証ID}
    getTransShipmentApi(target, licenceIdOfNextSection) {
      const licenceIds = target.collectLicenceInfo.map((l) => l.collectLicenceId).concat(licenceIdOfNextSection)
      axios
        .get(
          API_URL.ENTRUST.TRANSSHIPMENT_BY_LICENCE +
            `?licenceIdList=${licenceIds.join(",")}`
        )
        .then((res) => {
          target.transShipmentOpts = res.data.transshipmentSelectList;
          if (
            !target.transShipmentOpts.filter(
              (t) => t.id === target.transshipmentStorageId
            ).length
          ) {
            target.transshipmentStorageId = null; // 積替保管のリセット
          }
        })
        .catch((err) => {
          this.targetEntrustCollect.transShipmentOpts = [];
          console.log(err);
        });
    },
  },

  watch: {
    isCollectLicenceSelectModalShow() {
      if (!this.isCollectLicenceSelectModalShow) {
        this.targetEntrustCollect = {
          collectCompanyId: 0,
          collectLicenceInfo: [],
        };
      }
    },
    isCollectCommonLicenceSelectModalShow() {
      if (!this.isCollectCommonLicenceSelectModalShow) {
        this.targetEntrustCollectCommon = {
          collectCompanyId: 0,
          commonCollectLicenceInfo: [],
        };
      }
    },
  },
};
