import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      originalWasteTypeId: null,
    };
  },
  methods: {
    // かたまりを追加
    addWasteInfoHandler() {
      const
        wasteTypeId = this.form.entrustWasteList.length > 0 ? this.form.entrustWasteList[0].wasteTypeId : this.wasteType.INDUSTRIAL_WASTE,
        manifestTypeId = this.form.entrustWasteList.length > 0 ? this.form.entrustWasteList[0].manifestTypeId : this.manifestType.DIGITAL;
      const newList = {
        entrustWasteInfoId: null,
        wasteTypeId: wasteTypeId,
        wasteTypeName: null,
        manifestTypeId: manifestTypeId,
        manifestTypeName: null,
        jwnetItemTypeId: null,
        constitutionItemIdList: [
          {
            constitutionItemId: null,
          },
        ],
        cbaItemId: null,
        wasteNameId: null,
        plansQuantity: null,
        plansWasteUnitTypeId: null,
        plansWasteTermTypeId: null,
        limitQuantity: null,
        limitWasteUnitTypeId: null,
        limitWasteTermTypeId: null,
        shapeTypeIdList: [
          {
            shapeTypeId: null,
          },
        ],
        disposalWayType: [
          {
            disposalWayTypeId: null,
          },
        ],
        processInfo: "",
        wdsFileName: null,
        cbaItemOpts: [], // POSTしない。POST前に削除する
        wasteNameOpts: [], // POSTしない。POST前に削除する,
        activeStartDate: this.form.activeStartDate,
        activeEndDate: this.form.activeEndDate,
      };

      const newValidate = {
        jwnetItemTypeId: { isValid: true, errMsg: "" },
        cbaItemId: { isValid: true, errMsg: "" },
        wasteNameId: { isValid: true, errMsg: "" },
        constitutionItemId: { isValid: true, errMsg: "" },
        shapeTypeId: { isValid: true, errMsg: "" },
        disposalWayTypeId: { isValid: true, errMsg: "" },
        activeStartDate: { isValid: true, errMsg: ""},
        activeEndDate: { isValid: true, errMsg: ""},
        plansQuantity: { isValid: true, errMsg: "" }
      };

      this.form.entrustWasteList = [...this.form.entrustWasteList, newList];
      this.validate.wasteInfo = [...this.validate.wasteInfo, newValidate];

      // 1こ目から追加して廃棄物区分は産業廃棄物ですので、処分情報の事業場をリセット
      if (this.form.entrustWasteList.length === 1) {
        if (this.form.entrustWasteList[0].wasteTypeId === this.originalWasteTypeId) {
          this.form.entrustDisposalCompanyInfo.disposalSiteId = this.originalDisposalSiteId;
        }
        if (this.form.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE) {
          this.disposalSiteOpts = this.originalDisposalSiteByLicenceIdOpts;
        } else {
          this.disposalSiteOpts = this.originalDisposalSiteByCompanyIdOpts;
        }
      }

    },

    // かたまりを削除
    removeWasteInfoHandler(index) {
      this.form.entrustWasteList = this.form.entrustWasteList.filter(
        (v, i) => i !== index
      );

      this.validate.wasteInfo = this.validate.wasteInfo.filter(
        (v, i) => i !== index
      );
      // 廃棄物情報がない場合、処分事業場を未設定にする
      if (this.form.entrustWasteList.length === 0) {
        this.form.entrustDisposalCompanyInfo.disposalSiteId = null;
        this.disposalSiteOpts = [];
      }

      this.isRemoveWasteInfo = true;
    },

    // 廃棄物種類変更 DOMAIN.CBA_JWNET_ITEMS + {廃棄物種類ID}
    changeJwnetItemTypeHandler(entrustWaste) {
      if (this.resettable && !this.isRemoveWasteInfo) entrustWaste.cbaItemId = null; // CBA品目のリセット
      if (this.resettable && !this.isRemoveWasteInfo) entrustWaste.cbaItemOpts = []; // CBA品目のプルダウンリセット

      if (!entrustWaste.jwnetItemTypeId) return;
      return axios
        .get(API_URL.DOMAIN.CBA_JWNET_ITEMS + entrustWaste.jwnetItemTypeId)
        .then((res) => {
          entrustWaste.cbaItemOpts = res.data.cbaItemList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA品目変更 DOMAIN.CBA_WASTE_NAME + {CBA品目ID}
    changeCbaItemTypeHandler(entrustWaste, entrustWasteIndex) {
      if (this.resettable && !this.isRemoveWasteInfo) entrustWaste.wasteNameId = null; // 廃棄物名称のリセット
      if (this.resettable && !this.isRemoveWasteInfo) entrustWaste.wasteNameOpts = []; // 廃棄物名称のプルダウンリセット

      if (entrustWasteIndex === (this.form.entrustWasteList.length - 1)) {
        this.isRemoveWasteInfo = false;
      }

      if (!entrustWaste.cbaItemId) return;
      return axios
        .get(API_URL.DOMAIN.CBA_WASTE_NAME + entrustWaste.cbaItemId)
        .then((res) => {
          entrustWaste.wasteNameOpts = res.data.wasteNameList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 構成種類追加
    addConstitutionHandler(entrustWaste) {
      entrustWaste.constitutionItemIdList.push({
        constitutionItemId: null,
      });
    },

    // 構成種類削除
    removeConstitutionHandler(entrustWaste, targetType) {
      entrustWaste.constitutionItemIdList =
        entrustWaste.constitutionItemIdList.filter((co) => co != targetType);
    },

    //荷姿追加
    addShapeHandler(entrustWaste) {
      entrustWaste.shapeTypeIdList.push({
        shapeTypeId: null,
      });
    },

    //荷姿削除
    removeShapeHandler(entrustWaste, targetShape) {
      entrustWaste.shapeTypeIdList = entrustWaste.shapeTypeIdList.filter(
        (sh) => sh != targetShape
      );
    },

    // 処分方法追加
    addWayTypeHandler(entrustWaste) {
      entrustWaste.disposalWayType.push({
        disposalWayTypeId: null,
      });
    },

    // 処分方法削除
    removeWayTypeHandler(entrustWaste, targetWayType) {
      entrustWaste.disposalWayType = entrustWaste.disposalWayType.filter(
        (wt) => wt != targetWayType
      );
    },
  },
};
