import { QR_INFO_MAX_BYTE_SIZE, QUANITTY_REGEX } from "@/const";

export default {
  data() {
    return {
      validate: {
        // 排出事業者情報 ============================
        generateCompany: { isValid: true, errMsg: "" },
        generateFactoryIdList: { isValid: true, errMsg: "" },
        generateStoreList: { isValid: true, errMsg: "" },
        generateStore:[
          {
            contractStartDate: { isValid: true, errMsg: ""},
            contractEndDate: { isValid: true, errMsg: ""},
          }
        ],
        registMemberName: { isValid: true, errMsg: "" },
        generateMemberName: { isValid: true, errMsg: "" },
        generateDeliveryConfirmMail: { isValid: true, errMsg: "" },
        collectDeliveryConfirmMail: { isValid: true, errMsg: "" },
        disposalDeliveryConfirmMail: { isValid: true, errMsg: "" },

        anyMailList: [{ isValid: true, errMsg: "" }],
        anyMail1: { isValid: true, errMsg: "" },
        anyMail2: { isValid: true, errMsg: "" },
        anyMail3: { isValid: true, errMsg: "" },
        anyMail4: { isValid: true, errMsg: "" },
        anyMail5: { isValid: true, errMsg: "" },
        qrInfo1: { isValid: true, errMsg: "" },
        qrInfo2: { isValid: true, errMsg: "" },
        qrInfo3: { isValid: true, errMsg: "" },
        qrInfo4: { isValid: true, errMsg: "" },
        qrInfo5: { isValid: true, errMsg: "" },
        // 廃棄物情報 ============================
        wasteInfo: [
          {
            jwnetItemTypeId: { isValid: true, errMsg: "" },
            cbaItemId: { isValid: true, errMsg: "" },
            wasteNameId: { isValid: true, errMsg: "" },
            constitutionItemId: { isValid: true, errMsg: "" },
            shapeTypeId: { isValid: true, errMsg: "" },
            disposalWayTypeId: { isValid: true, errMsg: "" },
            activeStartDate: { isValid: true, errMsg: ""},
            activeEndDate: { isValid: true, errMsg: ""},
            plansQuantity: { isValid: true, errMsg: "" }
          },
        ],
        // 運搬委託契約先情報 ============================
        entrustCollectCompanyInfo: [
          {
            collectCompanyId: { isValid: true, errMsg: "" },
            contractStartDate: { isValid: true, errMsg: "" },
            contractEndDate: { isValid: true, errMsg: "" },
            // 経路情報 ============================
            collectFactoryId: { isValid: true, errMsg: "" },
            collectWayTypeId: { isValid: true, errMsg: "" },
            transshipmentStorageId: { isValid: true, errMsg: "" },
          },
        ],
        // 処分委託契約先情報 ============================
        disposalCompanyId: { isValid: true, errMsg: "" },
        contractStartDate: { isValid: true, errMsg: "" },
        contractEndDate: { isValid: true, errMsg: "" },
        disposalSiteId: { isValid: true, errMsg: "" },
      },

      isValidForm: {
        generate: true,
        wasteInfo: true,
        entrustCollectCompanyInfo: true,
        disposalInfo: true,
        memberName: true,
        entrustDeliveryConfirmation: true,
      },
    };
  },
  methods: {
    // 排出事業者情報 ============================
    generateCheck() {
      const v = this.validate;
      let isValidForm = true;

      if (!v.generateCompany.isValid) {
        v.generateCompany.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!this.tempVal.generateFactory.length) {
        v.generateFactoryIdList.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!this.form.generateStoreList.length) {
        v.generateStoreList.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if(this.form.generateStoreList.length) {
        for (let i = 0; i < this.form.generateStoreList.length; i++) {
          const v1 = this.validate.generateStore[i];
          if (!this.form.generateStoreList[i].contractStartDate) {
            v1.contractStartDate.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          } else if (this.form.generateStoreList[i].contractStartDate && this.form.generateStoreList[i].contractEndDate && this.form.generateStoreList[i].contractStartDate > this.form.generateStoreList[i].contractEndDate) {
            v1.contractEndDate.errMsg = "利用終了日は利用開始日以後の日付で正しく設定してください。";
            isValidForm = false;
          }
        }
      }

      return isValidForm;
    },

    // 廃棄物情報 ============================
    wasteInfoCheck() {
      let isValidForm = true;
      for (let i = 0; i < this.validate.wasteInfo.length; i++) {
        const v = this.validate.wasteInfo[i];
        if (!v.jwnetItemTypeId.isValid) {
          v.jwnetItemTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.cbaItemId.isValid) {
          v.cbaItemId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.wasteNameId.isValid) {
          v.wasteNameId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        //構成種類
        if (
          !this.form.entrustWasteList[i].constitutionItemIdList[0]
            .constitutionItemId
        ) {
          v.constitutionItemId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        // 荷姿
        if (!this.form.entrustWasteList[i].shapeTypeIdList[0].shapeTypeId) {
          v.shapeTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        // 処分方法
        if (!this.form.entrustWasteList[i].disposalWayType &&
          !this.form.entrustWasteList[i].disposalWayType[0].disposalWayTypeId
        ) {
          v.disposalWayTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!this.form.entrustWasteList[i].activeStartDate) {
          v.activeStartDate.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else if (this.form.entrustWasteList[i].activeStartDate && this.form.entrustWasteList[i].activeEndDate && this.form.entrustWasteList[i].activeStartDate > this.form.entrustWasteList[i].activeEndDate) {
          v.activeEndDate.errMsg = "利用終了日は利用開始日以後の日付で正しく設定してください。";
          isValidForm = false;
        }
        // 予定数量
        if (this.form.entrustWasteList[i].plansQuantity && !QUANITTY_REGEX.test(this.form.entrustWasteList[i].plansQuantity)) {
          v.plansQuantity.errMsg = "整数5桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      }

      return isValidForm;
    },

    // 運搬委託契約先情報 ============================
    collectCompanyInfoCheck() {
      let isValidForm = true;
      for (let i = 0; i < this.validate.entrustCollectCompanyInfo.length; i++) {
        const v = this.validate.entrustCollectCompanyInfo[i];
        if (!v.collectCompanyId.isValid) {
          v.collectCompanyId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.contractStartDate.isValid) {
          v.contractStartDate.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.contractEndDate.isValid) {
          v.contractEndDate.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }

        // 経路情報 ============================
        if (!v.collectFactoryId.isValid) {
          v.collectFactoryId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.collectWayTypeId.isValid) {
          v.collectWayTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.transshipmentStorageId.isValid) {
          v.transshipmentStorageId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }

      return isValidForm;
    },

    // 登録担当者名、引き渡し担当者名のバリデーションチェック ============================
    memberNameCheck() {
      const v = this.validate;
      let isValidForm = true;
      if (this.form.registMemberName && !v.registMemberName.isValid) {
        v.registMemberName.errMsg = "最大12文字で入力してください。";
        isValidForm = false;
      }

      if (this.form.generateMemberName && !v.generateMemberName.isValid) {
        v.generateMemberName.errMsg = "最大12文字で入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },

    // 受渡確認票情報のバリデーションチェック ============================
    entrustDeliveryConfirmationCheck() {
      const v = this.validate;
      let isValidForm = true;
      // 送付先email（排出）
      if (this.form.entrustDeliveryConfirmation.generateDeliveryConfirmMail && !v.generateDeliveryConfirmMail.isValid) {
        v.generateDeliveryConfirmMail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      // 送付先email（運搬）
      if (this.form.entrustDeliveryConfirmation.collectDeliveryConfirmMail && !v.collectDeliveryConfirmMail.isValid) {
        v.collectDeliveryConfirmMail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      // 送付先email（処分）
      if (this.form.entrustDeliveryConfirmation.disposalDeliveryConfirmMail && !v.disposalDeliveryConfirmMail.isValid) {
        v.disposalDeliveryConfirmMail.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      // 任意送付先email
      v.anyMailList.forEach((vMail, index) => {
        if (this.form.entrustDeliveryConfirmation.anyMailList[index] && !vMail.isValid) {
          vMail.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      })

      // QRチェック
      var qrInfoJoin = "";
      if (this.form.entrustDeliveryConfirmation.qrInfo1) {
        qrInfoJoin += this.form.entrustDeliveryConfirmation.qrInfo1;
      }
      if (this.form.entrustDeliveryConfirmation.qrInfo2) {
        qrInfoJoin += this.form.entrustDeliveryConfirmation.qrInfo2;
      }
      if (this.form.entrustDeliveryConfirmation.qrInfo3) {
        qrInfoJoin += this.form.entrustDeliveryConfirmation.qrInfo3;
      }
      if (this.form.entrustDeliveryConfirmation.qrInfo4) {
        qrInfoJoin += this.form.entrustDeliveryConfirmation.qrInfo4;
      }
      if (this.form.entrustDeliveryConfirmation.qrInfo5) {
        qrInfoJoin += this.form.entrustDeliveryConfirmation.qrInfo5;
      }
      if (this.countByte(qrInfoJoin) > QR_INFO_MAX_BYTE_SIZE) {
        v.qrInfo1.errMsg = "QR情報1~5は" + QR_INFO_MAX_BYTE_SIZE + "byte以内で入力してください。";
        v.qrInfo2.errMsg = "QR情報1~5は" + QR_INFO_MAX_BYTE_SIZE + "byte以内で入力してください。";
        v.qrInfo3.errMsg = "QR情報1~5は" + QR_INFO_MAX_BYTE_SIZE + "byte以内で入力してください。";
        v.qrInfo4.errMsg = "QR情報1~5は" + QR_INFO_MAX_BYTE_SIZE + "byte以内で入力してください。";
        v.qrInfo5.errMsg = "QR情報1~5は" + QR_INFO_MAX_BYTE_SIZE + "byte以内で入力してください。";

        isValidForm = false;
      }

      return isValidForm;
    },

    // 処分委託契約先情報 ============================
    disposalInfoCheck() {
      const v = this.validate;
      let isValidForm = true;

      if (!v.disposalCompanyId.isValid) {
        v.disposalCompanyId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.contractStartDate.isValid) {
        v.contractStartDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.contractEndDate.isValid) {
        v.contractEndDate.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }
      if (!v.disposalSiteId.isValid) {
        v.disposalSiteId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      return isValidForm;
    },

    // 排出事業場と廃棄物情報の期間は全く重チェック ============================
    overlapCheck() {
      let isValidForm = true;
      for (let i = 0; i < this.form.generateStoreList.length; i++) { 
        const g = this.form.generateStoreList[i];
        for (let j = 0; j < this.form.entrustWasteList.length; j++) {
          const e = this.form.entrustWasteList[j];
          if (g.contractEndDate || e.activeEndDate) {
            const minEndDate = [g.contractEndDate, e.activeEndDate].filter(i => i).sort()[0];
            const maxStartDate = [g.contractStartDate, e.activeStartDate].sort().reverse()[0];
            if (maxStartDate > minEndDate) {
              isValidForm = false
            }
          } else continue
        }
      }
      return isValidForm;
    },

    runValidate() {
      let isValidForm = false;
      this.isValidForm.memberName = this.memberNameCheck();
      this.isValidForm.entrustDeliveryConfirmation = this.entrustDeliveryConfirmationCheck();
      this.isValidForm.generate = this.generateCheck();
      this.isValidForm.wasteInfo = this.wasteInfoCheck();
      this.isValidForm.entrustCollectCompanyInfo =
        this.collectCompanyInfoCheck();
      this.isValidForm.disposalInfo = this.disposalInfoCheck();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }
      return isValidForm;
    },
  },
};
