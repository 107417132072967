import { render, staticRenderFns } from "./DisposalLicenceSelectModal.vue?vue&type=template&id=cc3959a8&"
import script from "./DisposalLicenceSelectModal.vue?vue&type=script&lang=js&"
export * from "./DisposalLicenceSelectModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports