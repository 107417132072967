import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      generateFactoryIdList: [],
    };
  },

  methods: {
    // 排出事業者変更時
    // CBA排出事業者所選択肢取得API   API_URL.ENTRUST.SELECT_FACTORY + {generatecompanyid}
    changeGenerateCompanyHandler() {
      return axios
        .get(API_URL.ENTRUST.SELECT_FACTORY + this.tempVal.generateCompany)
        .then((res) => {
          this.generateFactoryIdList = res.data.generateFactoryIdList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 排出事業場選択モーダル内で設定押下
    setSelectedStores(stores) {
      this.checkStartDateAble(); // 有効開始日の設定
      this.form.generateStoreList = stores;

      // バリデーションも追加
      this.validate.generateStore = []
      this.form.generateStoreList.forEach(() => {
        const newValidate = {
          contractStartDate: { isValid: true, errMsg: ""},
          contractEndDate: { isValid: true, errMsg: ""},
        };
        this.validate.generateStore.push(newValidate);
      })
    },

    // 排出事業所変更時
    changeGenerateFactoryHandler() {
      const selectedFactoryId = this.tempVal.generateFactory.map(f => f.id);
      this.form.generateStoreList = this.form.generateStoreList.filter(store => {
        return selectedFactoryId.includes(store.generateFactoryId); // 排出事業場のリセット
      });
    },
  },
};
