import axios from "axios";
import responseFormat from "./responseFormat";
import {API_URL} from "@/const";

export default {
  mixins: [responseFormat],
  data() {
    return {
      generateCompanyList: [],
      wasteTypeList: [],
      manifestTypeList: [],
      jwnetItemsOpt: [],
      constitutionItemList: [],
      wasteUnitOpt: [],
      termTypes: [],
      shapeOpt: [],
      disposalWayTypes: [],
      disposalCompanyList: [],
      collectCompanyList: [],
      collectWayTypeList: [],
      // 202202 CS 委託契約
      /**
       * 最終処分場選択肢情報
       */
      finalDisposalSiteSelectList: [],
      //処分場を編集用
      isOriginDisposalSiteId: "",
      entrust: {}
    };
  },

  methods: {
    // 委託契約書情報取得API  ENTRUST.GET + this.entrustId
    getEntrustApi(entrustId) {
      return axios
        .get(API_URL.ENTRUST.GET + entrustId)
        .then((res) => {
          const data = res.data;
          this.entrust = JSON.parse(JSON.stringify(data));
          this.form.activeStartDate = res.data.activeStartDate
          this.form.activeEndDate = res.data.activeEndDate
          this.form.isUpdate = res.data.isUpdate
          this.form.registMemberName = res.data.registMemberName
          this.form.generateMemberName = res.data.generateMemberName
          // 排出事業者情報
          this.formatGenerateResponse(data);
          // 廃棄物情報
          this.formatWasteListResponse(data);
          // 運搬委託契約先情報 経路含む
          this.formatCollectCompanyResponse(data);
          // 処分委託契約先情報
          this.formatDisposalCompanyResponse(data);
          this.isOriginDisposalSiteId = true;
          // 受渡確認票情報
          this.formatEntrustDeliveryConfirmationResponse(data);

        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA排出事業者選択肢取得API  GENERATOR_CHOICE
    getGeneratorChoiceApi() {
      return axios
        .get(API_URL.ENTRUST.CBA_GENERATOR_COMPANY)
        .then((res) => {
          this.generateCompanyList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物区分選択肢取得API  DOMAIN.WASTE_TYPE
    getWasteTypeApi() {
      return axios
        .get(API_URL.DOMAIN.WASTE_TYPE)
        .then((res) => {
          this.wasteTypeList = res.data.wasteTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // マニフェスト区分選択肢取得API  DOMAIN.MANIFEST_TYPE
    getManifestTypeApi() {
      return axios
        .get(API_URL.DOMAIN.MANIFEST_TYPE)
        .then((res) => {
          this.manifestTypeList = res.data.manifestTypeList;
          this.form.entrustWasteList[0].manifestTypeId =
            this.manifestTypeList[0].id;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物種類選択肢取得API   DOMAIN.JWNET_ITEM_TYPE
    getJwnetItemsApi() {
      return axios
        .get(API_URL.DOMAIN.JWNET_ITEM_TYPE)
        .then((res) => {
          this.jwnetItemsOpt = res.data.jwnetItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 構成種類選択肢取得API MANIFEST.CONSTITUTION_ITEM
    getConstitutionItemApi() {
      return axios
        .get(API_URL.MANIFEST.CONSTITUTION_ITEM)
        .then((res) => {
          this.constitutionItemList = res.data.wasteItems;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 廃棄物数量単位選択肢取得API    API_URL.DOMAIN.WASTE_UNIT
    getWasteUnitApi() {
      axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitOpt = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 期間選択肢取得API    API_URL.DOMAIN.TERM_TYPE
    getTermTypeApi() {
      axios
        .get(API_URL.DOMAIN.TERM_TYPE)
        .then((res) => {
          this.termTypes = res.data.termTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 荷姿選択肢取得API    API_URL.DOMAIN.SHAPE
    getShapeApi() {
      axios
        .get(API_URL.DOMAIN.SHAPE)
        .then((res) => {
          this.shapeOpt = res.data.shapeTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 処分方法選択肢取得API   DOMAIN.DISPOSAL_WAY_TYPE
    getDisposalWayTypesApi() {
      return axios
        .get(API_URL.DOMAIN.DISPOSAL_WAY_TYPE)
        .then((res) => {
          this.disposalWayTypes = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA処分事業者選択肢取得API ENTRUST.DISPOSAL_COMPANY
    getDisposalCompanyListApi() {
      return axios
        .get(API_URL.ENTRUST.DISPOSAL_COMPANY)
        .then((res) => {
          this.disposalCompanyList = res.data.disposalCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // CBA処分事業者選択肢取得API ENTRUST.COLLECT_COMPANY
    getCollectCompanyListApi() {
      return axios
        .get(API_URL.ENTRUST.COLLECT_COMPANY)
        .then((res) => {
          this.collectCompanyList = res.data.collectCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬方法選択肢取得API DOMAIN.COLLECT_WAY_TYPE
    getCollectCollectWayTypeApi() {
      return axios
        .get(API_URL.DOMAIN.COLLECT_WAY_TYPE)
        .then((res) => {
          this.collectWayTypeList = res.data.collectWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * 最終処分場選択肢取得API
     */
    getFinalDisposalSiteSelectListApi(disposalsiteId) {
      return axios
        .get(`${API_URL.ENTRUST.SELECT_FINAL_DISPOSAL_SITE}/${disposalsiteId}`)
        .then((res) => {
          this.finalDisposalSiteSelectList = res.data.finalDisposalSiteSelectList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
